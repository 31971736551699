import en from "../areas/main/assets/texts/en.json";
import ru from "../areas/main/assets/texts/ru.json";
import onboardingEn from "../areas/onboarding/assets/texts/en.json";
import MenuMockupEn from "../areas/main/assets/images/resources/menu.jpg";
import MenuMockupRu from "../areas/main/assets/images/resources/menu-ru.jpg";
import MenuSampleEn from "../areas/main/assets/images/resources/qr-menu-sample.jpg";
import DemoEn from "../areas/main/assets/videos/demo1.mp4";
import DemoRu from "../areas/main/assets/videos/demo1-ru.mp4";

let Texts = { ...en, onboarding: onboardingEn };
export let currentLang = "en";

export const switchLang = value => {
    switch(value) {
        case "ru":
            Object.assign(Texts, { ...ru, onboarding: onboardingEn });
            break;
        default:
            Object.assign(Texts, { ...en, onboarding: onboardingEn });
            break;

    }

    currentLang = value;
}

export const getMenuMockupImage = () => {
    switch(currentLang) {
        case "ru":
            return MenuMockupRu;
        default:
            return MenuMockupEn;        

    }
}

export const getMenuSampleImage = () => {
    return MenuSampleEn;
}

export const getQrDemoVideo = () => {
    switch(currentLang) {
        case "ru":
            return DemoRu;
        default:
            return DemoEn;        
    }
}

export default Texts;